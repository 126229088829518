import React from 'react'
import './NavBar.scss'

const NavBar = () => {
  return (
    <>
    
    </>
  )
}

export default NavBar