import React from 'react'
import './Footer.scss';

const Footer = () => {
  return (
    <div>
        <a href='https://ventu.dev/' target={'_blank'} rel="noreferrer"><h4>Un desarrollo de <span>Ventu</span></h4></a>
    </div>
  )
}

export default Footer